/* Sidebar */

.mdc-drawer {
  background: linear-gradient(#2a1c5a, #4b3a6e);
  background: url(../../images/sidebar/sidebar-bg.jpg);
  position: fixed;
  &--open,
  &__drawer {
    width: $mdc-drawer-width;
  }

  .mdc-drawer__header {
    padding: 0;
    margin: $mdc-drawer-list-item-margin;
    margin-top: 0;
    margin-bottom: 0;
    .brand-logo {
      display: block;
      padding: 28px 0;
    }
  }
  .mdc-drawer__content {
    .user-info {
      color: $mdc-drawer-link-color;
      margin: $mdc-drawer-list-item-margin;
      margin-bottom: 22px;
      font-weight: 300;
      .name {
        font-weight: 500;
      }
      p {
        margin-bottom: 6px;
      }
    }
    .mdc-drawer-menu {
      .mdc-drawer-item {
        cursor: pointer;
        display: block;
        height: auto;
        padding: 0;
        margin: $mdc-drawer-list-item-margin;
        @include border-radius(2px);

        &:before {
          z-index: -1;
        }

        &:active {
          &:before {
            opacity: 0;
          }
        }
        .mdc-expansion-panel-link,
        .mdc-drawer-link {
          height: 40px;
          line-height: 48px;
          padding: 0 0.75rem;
          @include display-flex();
          @include align-items(center);
          font-size: 0.875rem;
          font-weight: 500;
          text-decoration: none;
          color: $mdc-drawer-link-color;

          i {
            color: inherit;
            &.mdc-drawer-item-icon {
              font-size: 1.25rem;
              margin-right: 0.562rem;
            }
          }

          .mdc-drawer-arrow {
            margin-left: auto;
            font-size: 1.25rem;
            transition: all .3s ease;
            &.expanded {
              transform: rotate(90deg);
            }
          }

          &.expanded {
            .mdc-drawer-arrow {
              transform: rotate(90deg);
            }
          }

          &.active {
            background: #fff;
            color: #000;
            font-weight: bold;
          }
        }

        .mdc-expansion-panel {
          display: none;
        }
      }
    }
    .profile-actions {
      @include display-flex;
      margin: $mdc-drawer-list-item-margin;
      margin-top: 20px;
      margin-bottom: 38px;
      padding: 0 0.75rem;
      a {
        font-weight: 500;
        font-size: 12px;
        color: #bababa;
        padding: 0 10px;
        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
      .divider {
        width: 1px;
        height: 10px;
        margin-top: 1px;
        background: #bababa;
      }
    }
    .premium-card {
      margin: $mdc-drawer-list-item-margin;
      margin-right: 27px;
      margin-bottom: 50px;
      background: #5458e1;
      color: #fff;
      padding: 25px;
      p {
        line-height: 1.3;
      }
      .icon-card {
        width: 32px;
        height: 32px;
        padding: 0;
        box-shadow: none;
        @include display-flex;
        @include align-items(center);
        @include justify-content(center);
        background: rgba(255, 255, 255, .2);
        i {
          font-size: 20px;
        }
      }
      .mdc-button {
        height: 40px;
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .ps {
    .ps__rail-y {
      &:hover {
        background: transparent;
        .ps__thumb-y {
          width: 6px;
        }
      }
      .ps__thumb-y {
        background-color: #6d6386;
        width: 4px;
      }
    }
  }
}