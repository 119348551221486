/* CodeMirror */

.CodeMirror {
  font-size: $default-font-size;
  font-family: $type1;
  height: auto;
  text-align: left;
  min-height: auto;
}
.CodeMirror-scroll {
  min-height: auto;
  line-height: 1.4;
  padding: 30px 10px;
  // .CodeMirror-code {
  //   .CodeMirror-gutter-wrapper {
  //     z-index: 1;
  //   }
  // }
}