@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../../vendors/material-design-icons/iconfont/MaterialIcons-Regular.eot); /* For IE6-8 */
  src: local('Material Icons'),
       local('MaterialIcons-Regular'),
       url(../../vendors/material-design-icons/iconfont/MaterialIcons-Regular.woff2) format('woff2'),
       url(../../vendors/material-design-icons/iconfont/MaterialIcons-Regular.woff) format('woff'),
       url(../../vendors/material-design-icons/iconfont/MaterialIcons-Regular.ttf) format('truetype');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}



// Roboto Font

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Light.eot');
  src: url('../../fonts/roboto/Roboto-Light.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/roboto/Roboto-Light.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Light.woff') format('woff'),
      url('../../fonts/roboto/Roboto-Light.ttf') format('truetype'),
      url('../../fonts/roboto/Roboto-Light.svg#Roboto-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Regular.eot');
  src: url('../../fonts/roboto/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/roboto/Roboto-Regular.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Regular.woff') format('woff'),
      url('../../fonts/roboto/Roboto-Regular.ttf') format('truetype'),
      url('../../fonts/roboto/Roboto-Regular.svg#Roboto-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Bold.eot');
  src: url('../../fonts/roboto/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/roboto/Roboto-Bold.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Bold.woff') format('woff'),
      url('../../fonts/roboto/Roboto-Bold.ttf') format('truetype'),
      url('../../fonts/roboto/Roboto-Bold.svg#Roboto-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Medium.eot');
  src: url('../../fonts/roboto/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/roboto/Roboto-Medium.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Medium.woff') format('woff'),
      url('../../fonts/roboto/Roboto-Medium.ttf') format('truetype'),
      url('../../fonts/roboto/Roboto-Medium.svg#Roboto-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../fonts/roboto/Roboto-Thin.eot');
  src: url('../../fonts/roboto/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
      url('../../fonts/roboto/Roboto-Thin.woff2') format('woff2'),
      url('../../fonts/roboto/Roboto-Thin.woff') format('woff'),
      url('../../fonts/roboto/Roboto-Thin.ttf') format('truetype'),
      url('../../fonts/roboto/Roboto-Thin.svg#Roboto-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}
