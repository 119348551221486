.mdc-card {
  background: $mdc-card-bg;
  padding: $mdc-card-padding;
  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
  border-radius: 2px;
  .card-title {
    font-size: 20px;
    margin-bottom: 6px;
    font-weight: normal;
    @media(max-width: 768px) {
      font-size: 16px;
    }
  }
  .card-sub-title {
    font-size: 14px;
    font-weight: 300;
  }
}