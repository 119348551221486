// stylelint-disable plugin/selector-bem-pattern
.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 10px;
}

.mdc-text-field--with-leading-icon .mdc-text-field__icon, 
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  bottom: 10px;
}

.mdc-checkbox--custom {
  @include mdc-checkbox-container-colors(#ff00a5, orange, #ff00a5, purple)
}