/* Utilities */
.font-weight-normal {
  font-weight: normal;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-medium {
  font-weight: 500;
}

.font-weight-bold {
  font-weight: bold;
}

.text-center {
  text-align: center !important;;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-small {
  font-size: $default-font-size;
}

.icon-lg {
  font-size: 2.5rem;
}

.icon-md {
  font-size: 1.875rem;
}

.icon-sm {
  font-size: 1rem !important;
}

.card-padding {
  padding: $mdc-card-padding;
}

.stretch-card {
  @include display-flex;
  @include align-items(stretch);
  @include justify-content(stretch);

  .mdc-card {
    width: 100%;
    @include justify-content(flex-start);
  }
}

.d-none { display: none !important }
.d-block { display: block !important }
.d-md-block { @media(min-width: 768px) {display: block !important} }
.d-lg-block { @media(min-width: 992px) {display: block !important} }
.d-xl-block { @media(min-width: 14200px) {display: block !important} }

.d-sm-block {
  @media(min-width: 576px) {
     display: block !important 
  }
}

.d-md-block {
  @media(min-width: 768px) {
     display: block !important 
  }
}

.d-lg-block {
  @media(min-width: 992px) {
     display: block !important 
  }
}

.d-xl-block {
  @media(min-width: 1200px) {
     display: block !important 
  }
}

.d-flex {
  @include display-flex();
}

.d-sm-flex { 
  @media(min-width: 576px) {
    display: flex !important;
  }
}

.d-md-flex { 
  @media(min-width: 768px) {
    display: flex !important;
  }
}

.d-lg-flex { 
  @media(min-width: 992px) {
    display: flex !important;
  }
}

.d-xl-flex { 
  @media(min-width: 1200px) {
    display: flex !important;
  }
}

.align-items-start {
  @include align-items(flex-start);
}

.align-items-center {
  @include align-items(center);
}

.align-items-end {
  @include align-items(flex-end);
}

.justify-content-start {
  @include justify-content(flex-start);
}

.justify-content-center {
  @include justify-content(center);
}

.justify-content-end {
  @include justify-content(flex-end);
}

.justify-content-between {
  @include justify-content(space-between);
}

.flex-grow-1 {
  @include flex-grow(1);
}

.flex-row {
  @include flex-direction(row);
}

.flex-column {
  @include flex-direction(column);
}

.flex-wrap {
  @include flex-wrap(wrap);
}

.w-100 {
  width: 100%;
  min-width: 100%;
}

.w-75 {
  width: 75%;
  min-width: 75%;
}

.w-50 {
  width: 50%;
  min-width: 50%;
}

.bg-white {
  background: $white;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.text-white {
  color: $white !important;
}

.text-muted {
  color: $text-muted !important;
}

.text-green {
  color: $green !important;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.my-0 {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
}

.my-1 {
  margin-bottom: 0.25rem !important;
  margin-top: 0.25rem !important;
}

.my-2 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}

.my-3 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}

.my-4 {
  margin-bottom: 1.5rem !important;
  margin-top: 1.5rem !important;
}

.my-5 {
  margin-bottom: 3rem !important;
  margin-top: 3rem !important;
}

.py-0 {
  padding-bottom: 0 !important;
  padding-top: 0 !important;
}

.py-1 {
  padding-bottom: 0.25rem !important;
  padding-top: 0.25rem !important;
}

.py-2 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}

.py-3 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.py-4 {
  padding-bottom: 1.5rem !important;
  padding-top: 1.5rem !important;
}

.py-5 {
  padding-bottom: 3rem !important;
  padding-top: 3rem !important;
}

.mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.mx-3 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-4 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.mx-5 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.mx-md-5 {
  @media(min-width: 768px) {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
}

.px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.px-3 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-4 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-5 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-md-5 {
  @media(min-width: 768px) {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
}

.pl-1 {
  padding-left: .25rem !important;
}

.pl-2 {
  padding-left: .5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-1 {
  padding-right: .25rem !important;
}

.pr-2 {
  padding-right: .5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pt-1 {
  padding-top: .25rem !important;
}

.pt-2 {
  padding-top: .5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-0 {
  padding-bottom: 0rem !important;
}

.pb-1 {
  padding-bottom: .25rem !important;
}

.pb-2 {
  padding-bottom: .5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.mb-sm-0 {
  @media(min-width: 576px) {
    margin-bottom: 0 !important;
   }
}

.ml-md-4 {
  @media(min-width: 768px) {
    margin-left: 1.5rem !important;
   }
}

.ml-lg-4 {
  @media(min-width: 992px) {
    margin-left: 1.5rem !important;
   }
}

.w-lg-50 {
  @media(min-width: 992px) {
    width: 50%;
    min-width: 50%;
   }
}

.overflow-visible {
  overflow: visible;
}

.bg-blue {
  background: $blue;
}

.bg-red {
  background: $red;
}

.text-red {
  color: $red;
}

.text-blue {
  color: $blue;
}

.text-yellow {
  color: $yellow;
}

.border-bottom {
  border-bottom: 1px solid $border-color;
}

.border-right {
  border-right: 1px solid $border-color;
}

.border-top {
  border-top: 1px solid $border-color;
}

.rounded {
  @include border-radius(3px);
}

.tx-8 { font-size: 8px }
.tx-9 { font-size: 9px }
.tx-10 { font-size: 10px }
.tx-11 { font-size: 11px }
.tx-12 { font-size: 12px }
.tx-13 { font-size: 13px }
.tx-14 { font-size: 14px }
.tx-15 { font-size: 15px }
.tx-16 { font-size: 16px }

.grid-gap-0 {
 grid-gap: unset;
}

.box-shadow-none {
  box-shadow: none !important;
}

.button-box-shadow {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.09);
}

.text-black {
  color: #000000;
}

.rounded-0 {
  border-radius: 0 !important;
}

.border-0 {
  border: none !important;
}